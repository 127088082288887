<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12>
                <v-switch v-model="item.enable" label="Habilitado" class="my-0 py-0" />
            </v-flex>
            <v-flex xs12 v-if="!item.id">
                <v-text-field label="E-mail" v-model="item.email" :rules="[rules.required, rules.email]" />
                <v-text-field label="Digite a senha" v-model="item.password" min="8" :rules="[rules.required, rules.password]"
                    :append-icon="show1 ? 'visibility' : 'visibility_off'" :type="show1 ? 'text' : 'password'" @click:append="show1 = !show1" counter required />
                <v-text-field label="Confirme a senha" v-model="confirmPassword" min="8" :rules="confirmPasswordRules" :append-icon="show2 ? 'visibility' : 'visibility_off'"
                    :type="show2 ? 'text' : 'password'" @click:append="show2 = !show2" counter required />
            </v-flex>
            <v-flex xs12 v-else>
                <v-text-field label="E-mail" v-model="item.email" disabled />
            </v-flex>
            <v-flex xs12 v-if="item.roles.length == 1 && item.roles[0] == 'INTEGRATION'">
                <v-alert icon="shield" prominent text type="error" v-if="item.id > 0"> As regras desse tipo de usuário não podem ser modificadas. </v-alert>
                <v-alert icon="shield" prominent text type="warning" v-else> Criação permitida apenas para usuário de integração. </v-alert>
            </v-flex>
            <v-flex xs12 v-else>
                <v-autocomplete v-model="item.roles" :items="roles" item-value="key" item-text="description" label="Regras" class="my-0 py-0" deletable-chips multiple
                    small-chips />
            </v-flex>
            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            confirmPassword: null,
            show1: false,
            show2: false,
            itemClean: {
                email: "",
                password: "",
                roles: ["INTEGRATION"],
            },
            item: { ...this.itemClean },
            roles: [
                { key: "ADMIN", description: "Administrador" },
                { key: "OPERATOR", description: "Operador" },
                { key: "ADMIN_CONDOMINIUM", description: "Administrador de condomínio" },
                { key: "CLIENT", description: "Parceiro carro" },
                { key: "CLIENT_BIKE", description: "Parceiro bike" },
                { key: "USER", description: "Usuário" },
            ],
            confirmPasswordRules: [(v) => v === this.item.password || "Senha diferentes"],
        };
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.item = { ...newVal };
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>